<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Conditions Générales d'utilisation
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner">
                <h2>Définition</h2>
                <ul>
                  <li>
                    «Bénéficiaire » : désigne toute personne physique majeure
                    qui bénéficie d’une Offre de la Société.
                  </li>
                  <li>
                    «Contrat » : désigne le Bulletin de souscription, les
                    présentes Conditions générales, les éventuelles conditions
                    particulières ainsi que les Notices des Équipements
                    sélectionnés par le Bénéficiaire. La description des offres
                    et services et les prix associés. Ces éléments sont
                    disponibles dans l’Espace client du Bénéficiaire.
                  </li>
                  <li>
                    «Equipements » : désigne les éléments assistant Google
                    (google home ou google nest) ou Amazon Alexa (echo, echo
                    show) .
                  </li>
                  <li>
                    «Offre » : désigne l’une quelconque des offres commerciales,
                    ainsi que les services optionnels, proposés par la Société.
                  </li>
                  <li>
                    «Partie(s) » : désigne la Société ou le Bénéficiaire.
                  </li>
                  <li>
                    «Services » : désigne les services proposés par la Société,
                    tels que décrits à l’article 4 des présentes.
                  </li>
                </ul>
                <h2>Prérequis</h2>
                <p>
                  Pour utiliser le service,le Bénéficiaire doit disposer à son
                  domicile d'un assistant Google (google home ou google nest) ou
                  Amazon Alexa (echo, echo show) en état de fonctionnement et
                  connecté à internet.
                </p>
                <h2>1. Conditions générales de Vente</h2>
                <p>
                  Les présentes Conditions générales s'appliquent à tout achat
                  du service d'alerting "mes amis de confiance" proposé par
                  Shirkalab sur ce site Web. Les caractéristiques principales
                  des Services sont présentées sur le site Web et dans les
                  présentes Conditions générales. Le Bénéficiaire est tenu d'en
                  prendre connaissance avant toute souscription aux Services. Le
                  choix et l'achat d'un Service sont de la seule responsabilité
                  du Bénéficiaire.
                </p>
                <p>
                  Ces conditions s'appliquent à l'exclusion de toutes autres
                  conditions, et notamment celles applicables pour d'autres
                  circuits de commercialisation des Services. Ces Conditions
                  générales sont accessibles à tout moment sur le site Internet
                  et prévaudront, le cas échéant, sur toute autre version ou
                  tout autre document contradictoire. Elles sont complétées par
                  des Dispositions particulières également disponibles sur ledit
                  Site. Conformément à la loi informatique et Liberté du 6
                  janvier 1978 modifiée et le RGPD (règlement général sur la
                  protection des données) entré en vigueur le 25 mai 2018, le
                  Bénéficiaire et/ou le Souscripteur disposent, à tout moment,
                  d'un droit d'accès, de rectification, d'opposition,
                  d'effacement et de portabilité de l'ensemble de leurs données
                  personnelles en adressant un courriel à :
                  alerting@shirkalab.io
                </p>
                <h3>1.1 Acceptation des Conditions générales de Vente</h3>
                <p>
                  Le Bénéficiaire reconnaît avoir la capacité requise pour
                  souscrire un contrat et acquérir les Services proposés sur le
                  site Web de la Société shirkalab. Le Bénéficiaire déclare
                  avoir pris connaissance des présentes Conditions générales et
                  les avoir acceptées en cochant la case prévue à cet effet lors
                  de la souscription en ligne.
                  <br />
                  Il reconnaît avoir eu connaissance de la possibilité
                  d’enregistrer et/ou imprimer les présentes Conditions
                  générales à partir du Site Web. La validation de la
                  souscription aux Services par le Bénéficiaire vaut acceptation
                  sans restriction ni réserve des présentes Conditions
                  générales. Les Conditions générales sont jointes au courriel
                  envoyé au Souscripteur avec la confirmation de sa souscription
                  au service. Les Conditions générales resteront accessibles
                  dans l’Espace client du Bénéficiaire et/ou Souscripteur.
                </p>
                <h3>1.2 Modification des Conditions générales de Ventes</h3>
                <p>
                  Les présentes Conditions générales de Vente demeureront en
                  vigueur tant qu’elles resteront accessibles sur le Site. Il
                  est précisé que storylab se réserve le droit de modifier à
                  tout moment les présentes Conditions générales. La
                  modification ne prendra effet que pour les commandes passées
                  après la date de mise en ligne de la modification, sauf si
                  cette modification résulte d’une obligation imposée par une
                  loi impérative immédiatement applicable sur le territoire
                  français. Dans cette dernière hypothèse, la société en
                  informera immédiatement le Bénéficiaire . Le Bénéficiaire est
                  informé que les présentes Conditions générales pourront être
                  amenées à évoluer et ce faisant à être modifiées.
                </p>
                <h2>2. Souscription</h2>
                <p>
                  Le Bénéficiaire passe commande conformément aux indications
                  qui lui sont données dans l’espace moncompte du site. Il
                  s'engage à remplir le bulletin de souscription en veillant à
                  donner les informations nécessaires complètes et exactes. En
                  tout état de cause, le Bénéficiaire est responsable des
                  informations saisies. En cas d’erreur de sa part, shirkalab ne
                  saurait être tenue pour responsable des manquements engendrés
                  dans la mise en œuvre des Services. L'acceptation et la
                  confirmation de la commande sont concrétisées au travers de
                  différentes et successives saisies de données sur plusieurs
                  pages-écrans.
                </p>
                <h2>3. Espace client</h2>
                <p>
                  L’accès à l’Espace client est possible après la finalisation
                  de la souscription. Le Bénéficiaire doit utiliser ses
                  identifiants Google ou Amazon pour accéder à son Espace
                  client.
                </p>
                <h3>3.1 Identification mes amis de confiance</h3>
                <p>
                  Le Bénéficiaire renseigne l’identité du ou des amis de
                  confiance avec leur nom, prénom, adresse, numéro de téléphone
                  et leur adresse électronique. La désignation d’au minimum un
                  ami de confiance est nécessaire à la mise en œuvre du service.
                </p>
                <h3>3.2 Validation de la souscription à un service</h3>
                <p>
                  Dès que le Bénéficiaire enregistre sa souscription en cliquant
                  sur l'icône "Valider ma commande et payer", il est considéré
                  comme ayant accepté en connaissance de cause et sans réserve
                  les présentes Conditions générales, et tous les éléments
                  constitutifs du contrat de souscription que ce soient les
                  prix, volumes et quantités et type (ou nature) des produits
                  proposés à la vente et commandés. Il appartient donc au
                  Bénéficiaire de vérifier l'exactitude de la commande.
                  Toutefois, le Bénéficiaire dispose, pendant un délai de
                  quatorze (14) jours, du droit de se rétracter, en envoyant un
                  mail à alerting@shirkalab.io.
                </p>
                <p>
                  Shirkalab transmettra par voie de courrier électronique une
                  confirmation de la commande rappelant tous les éléments qui la
                  constitue. La vente ne sera considérée comme définitive
                  qu’après l’envoi de cette confirmation. Cette confirmation
                  devra être conservée par le Bénéficiaire. À cet email de
                  confirmation sera joint un lien web vers ces présentes
                  Conditions générales.
                </p>
                <p>
                  Toute commande passée sur le site Web constitue la formation
                  d'un contrat conclu à distance entre le Bénéficiaire et
                  Shirkalab. Shirkalab se réserve le droit d'annuler ou de
                  refuser toute commande d'un Bénéficiaire avec lequel il
                  existerait un litige relatif au paiement d'une commande
                  antérieure.
                </p>
                <h3>
                  3.3 Confirmation de souscription et archivage de la
                  souscription
                </h3>
                <p>
                  Les commandes sont archivées chez l'hébergeur de shirkalab
                  pendant une durée de cinq (5) ans à compter de la dernière
                  commande Le Bénéficiaire peut accéder au détail de ses
                  commandes en se connectant à son espace client.
                </p>

                <h2>4. Nature de la prestation</h2>
                <p>
                  Mes amis de confiance est un service d'alerting disponible sur
                  les assistants vocaux Amazon Alexa et Google Assistant. Ce
                  service permet d’envoyer des notifications pour alerter ses
                  proches quand le Bénéficiaire a besoin d’assistance simplement
                  en utilisant sa voix. Lorsque le Bénéficiaire demande de
                  l’assistance, le service passe un appel vocal, envoie un SMS
                  et un e-mail à chacun des contacts pré-renseigné par le
                  Bénéficiaire sur ce site web lors de son inscription. Ce
                  service n’est pas un service de téléassistance. Mes amis de
                  confiance est une solution technique qui permet de notifier
                  par sms , appel vocal et mail chacun des contacts que vous
                  avez pré-renseigné sur notre plateforme lors de votre
                  inscription. Ce service ne contacte pas les services de
                  secours. C’est aux proches notifiés par la demande
                  d’assistance d’évaluer la situation et de prévenir les secours
                  s’il y a lieu.
                </p>

                <h2>5. Installation</h2>
                <p>
                  L'installation et le paramétrage du service sont à la charge
                  du bénéficiaire. Shirkalab ne pourra être tenu responsable
                  d'un mauvais paramétrage ou une mauvaise utilisation du
                  service.
                </p>

                <h3>5.1 Installation sur Alexa</h3>
                <p>
                  Pour utiliser le service sur Amazon Alexa le bénéficiaire
                  doit:

                  <br />- Souscrire à un pack <br />- Se connecter avec son
                  compte Amazon Alexa utilisé lors de son inscription (le même
                  compte qui a servi à paramétrer son assistant ) <br />- Saisir
                  les coordonnées ( nom , prénom téléphone fixe ou portable ,
                  e-mail) d'une liste d’amis , proches , voisins de confiance
                  susceptibles de répondre à une demande d’assistance. Le
                  service notifiera par mail ou sms tous les contacts du
                  bénéficiaire qu’ils font partie de son réseau de confiance.
                  <br />- Activer la skill sur Alexa en demandant " Alexa lance
                  mes amis de confiance" <br />- Lors de cette première
                  connexion, l’assistant demandera au bénéficiaire de se
                  connecter avec son compte Alexa puis confirmera que la
                  procédure d’initialisation est terminée en donnant la liste
                  des contacts de confiance . <br />- Une fois ces étapes
                  d'initialisation terminées le bénéficiaire pourra utiliser le
                  service quand il le souhaite en demandant à son assistant:
                  "Alexa, demande à mes amis de confiance de l'assistance."
                </p>

                <h3>5.1 Installation sur Google</h3>
                <p>
                  Pour utiliser le service sur Google assistant le bénéficiaire
                  doit:

                  <br />- Souscrire à un pack <br />- Se connecter avec son
                  compte Google utilisé lors de son inscription (le même compte
                  qui a servi à paramétrer son assistant ) <br />- Saisir les
                  coordonnées ( nom , prénom téléphone fixe ou portable ,
                  e-mail) d'une liste d’amis , proches , voisins de confiance
                  susceptibles de répondre à une demande d’assistance . Le
                  service notifiera par mail ou sms tous les contacts du
                  bénéficiaire qui font partie de son réseau de confiance.
                  <br />- Activer l'action sur Google en demandant " Hey Google,
                  parler avec mes amis de confiance" <br />- Lors de cette
                  première connexion, l’assistant demandera au bénéficiaire de
                  se connecter avec son compte Google puis confirmera que la
                  procédure d’initialisation est terminée en donnant la liste
                  des contacts de confiance . <br />- Une fois ces étapes
                  d'initialisation terminées le bénéficiaire pourra utiliser le
                  service quand il le souhaite en demandant à son assistant: "Ok
                  Google, demande à mes amis de confiance de l'assistance."
                </p>

                <h3>6. Durée du contrat</h3>
                <p>
                  La durée du contrat est fixée un mois. À son terme, il se
                  renouvelle par tacite reconduction pour des périodes d’une
                  même durée.
                </p>

                <h3>7. Transfert - cession</h3>
                <p>
                  Compte tenu de la nature même du contrat, le bénéficiaire ne
                  peut céder ou transférer les droits résultant pour lui du
                  présent contrat.
                </p>

                <h3>8. Responsabilité de shirkalab</h3>
                <p>
                  Shirkalab s’engage à faire ses meilleurs efforts pour assurer
                  que l'accès et le fonctionnement du service soient assurés 24
                  heures sur 24 et 7 jours sur 7 sans interruption, étant
                  précisé que cet engagement ne constitue qu’une obligation de
                  moyens. Toutefois, Shirkalab ne peut voir sa responsabilité
                  recherchée en cas d'indisponibilité temporaire du service (i)
                  en cas de maintenance des serveurs sur lequel il est hébergé
                  (ii) en cas de survenance d'événements extérieurs à sa volonté
                  et à son contrôle et (iii) en raison de cas de force majeure.
                  La responsabilité de shirkalab ne peut être engagée en raison
                  de ces interruptions et des conséquences qui peuvent en
                  découler pour le bénéficiaire.
                </p>

                <h3>9. hébergement</h3>
                <p>
                  L'ensemble du code et des données nécessaire au bon
                  fonctionnement du service sont hébergés sur des serveurs
                  Amazon Web Service situés en Irlande. AMAZON WEB SERVICES EMEA
                  SARL, établissement secondaire situé au 31 Place des Corolles,
                  Tour Carpe Diem, 92400 COURBEVOIE (France), immatriculé au RCS
                  de NANTERRE sous le numéro 831 001 334.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "About",
          to: "",
          disabled: true
        }
      ]
    };
  }
};
</script>
